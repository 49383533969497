<template>
    <div class="godown">
        <div class="down-logo">
            <img src="../assets/images/nav_logo_1.jpg" alt="">
        </div>
         <div class="down-jiesao">
            <div class="xinhao">
                <img src="../assets/images/xjdown_1.png" alt="">
                <div class="xinhao-wenzi">
                    <ul>
                        <li>
                            <p>梦幻新仙剑（内测） &nbsp;&nbsp;&nbsp;</p>
                            <span>上线送元宝*18888、银票*38888</span>
                        </li>
                        <li>
                            <p>版本：1.0.0 </p>
                            <span>大小：323.9</span>
                        </li>
                        <li>
                            <p>日期：2018-07-25</p>
                            <span>次数：4043次下载</span>
                        </li>
                    </ul>
                </div>
            </div>
             <div class="lianjie">
        <a id="dnbtn" download href="https://kx.sddrbelt.com/xianjqxzhh.apk" rel="nofollow"  style="background-position: 0px 0px;color:#fff;">高速下载</a>
            </div>
        <div class="down_img">
            <img src="../assets/images/xj.jpg" alt="">
            <img src="../assets/images/xj_1.jpg" alt="">
            <img src="../assets/images/xj_2.jpg" alt="">
        </div>
        <div class="down_jieshao">
            <h1>游戏介绍</h1>
           <p>《仙剑奇侠传回合（内测）》是仙剑史上首款3DMMORPG回合制大型手游，1-6代全系列经典人物首次齐聚，多款飞行坐骑可轻松实现御剑飞行的最初梦想。游戏首次引入“仙盟剑盟“阵营概念，是仙剑IP手游中唯一引入阵营概念的产品。游戏庞大的故事体系、丰富的培养系统、完善的社交系统，深受广大籼米期待。国民第一大IP与经典回合的完美碰撞究竟会给我们带来怎样的惊喜?</p>
        </div>
        <div class="down_jieshao">
            <h1>游戏特色</h1>  
            <p>福利设置充值返利Vip价格表</p>
            <p>内测时间</p>
            <p>2018年7月25日~2018年8月8日</p>
            <p>一、 充值补偿</p>
            <p>凡是在《仙剑奇侠传回合（内测）》内测期间充值的玩家，充值金额将在公测100%返还，同时享受VIP、线上线下充值活动奖励，并额外赠送30%的充值返利。(例：内测期间充值1000元，则在公测时将获得1000元的充值返还，并享受1300元的充值返利。)</p>
            <p>返还方式：联系下载平台客服，提供内测充值账号、充值订单、及公测账号信息，充值及返利将在一个工作日内到达你的公测账号。</p>
            <p>二、 内测玩家礼包</p>
            <p>《仙剑奇侠传回合（内测）》的内测玩家，在正式上线时凭内测账号信息即可领取内测玩家专属礼包一份!</p>
            <p>内测礼包：风云再起称号(1天)、奇侠召唤符*1、仙鸟的羽毛*1、2星紫色阵法灵珠*1、灵葫仙丹*30 (参与内测免费领取)</p>
            <p>内测充值玩家礼包：风云再起称号(永久)、奇侠召唤符*1、仙鸟的羽毛*1、2星紫色阵法灵珠*1、灵葫仙丹*30(充值任意金额)</p> 
            <p>1、注册角色赠送：元宝*18888、银票*38888、铜币*500W</p>
            <p>2、七日登陆每日豪礼送不停，次日登录赠送绑定元宝*6666，三日登录赠送灵宠绝技包，四日登录小蛮，六日赠送高级七星珠宝箱，七日赠送元宝*8888。</p>
            <p>3、每日签到领神兽，海量元宝、神兽精魄、宝石、奇侠奖励天天送不停。</p>
            <p>4、升级有好礼稀有宠物蛋、神兵图纸、各类养成材料、万贯钱财升级就免费送。</p>
            <p>5、二十三载仙剑情，寻仙大礼回馈籼米，李忆如、李逍遥、赵灵儿时装、坐骑仙鸟、神兵、高级宠物技能全部免费送!!</p>
            <p>6、开服有好礼，上万元宝来玩就送!!</p>
            <p>7、首充双倍1:400，充值比例1:200。</p>
            <p>8、首充即赠送仙剑女神赵灵儿、专属绝版称号、极品神兵，还有永久飞剑等你拿!</p>
            <p>9、超值基金10倍返还，总共可领取248800元宝。</p>
            <p>10、开服竞技送大礼，40W元宝大奖、大量神兽精魄、高阶宝石袋、绝世神兵以及海量修炼材料，拿到手软!</p>        
        </div>
        </div>
        <FooterInfo></FooterInfo>  
    </div>
</template>
<script>
import FooterInfo from "../components/footer";
export default {
  components: {
    FooterInfo
  }
};
</script>

<style>
.godown {
  padding: 20px;
}
.godown .down-logo {
  padding: 20px;
}
.godown .down-jiesao {
  margin-top: 20px;
  width: 85%;
  padding: 20px;
  background-color: #fcfcfc;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}
.godown .down-jiesao .xinhao {
  display: flex;
  flex-direction: row;
}
.godown .down-jiesao .xinhao > img {
  width: 72px;
  height: 72px;
}
.godown .dnbtn {
  width: 160px;
  height: 40px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi {
  margin-left: 20px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li {
  display: flex;
  flex-direction: row;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:first-child {
  line-height: 30px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:first-child p {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:first-child span {
  font-size: 12px;
  color: red;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:not(:first-child) {
  line-height: 22px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:not(:first-child) span {
  margin-left: 20px;
}
.godown .down-jiesao .lianjie {
  position: relative;
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: red;
  border-radius: 8px;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 16px;

  margin-top: 20px;
}

.godown .down-jiesao .down_img {
  width: 100%;
  display: flex;
  padding: 20px;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.godown .down-jiesao .down_img img {
  width:30%;
  height: 100%;
}
.godown .down-jiesao .down_jieshao {
  width: 100%;
  padding: 20px;
}
.godown .down-jiesao .down_jieshao h1 {
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
}
</style>

